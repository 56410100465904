<template>
    <div id="header">
        <div class="logo">
            <img src="~@/assets/images/logo.png">
            <span>亿动赛事管理平台</span>
        </div>
        <div class="loginInfo">
            <div class="name">{{$store.state.leagueInfo.name}}</div>
            <ul>
                <li title="点击切换赛事" @click="go('/SelectLeague?type=1')">切换赛事</li>
                <li title="编辑当前赛事信息" @click="go('/UpdateMainEvent')">编辑赛事信息</li>
                <li class="loginOut" @click="loginOut">登出</li>
            </ul>
        </div>
    </div>
</template>
<script>
import {
    Logout
} from '@/api';
export default {
    data(){
        return {}
    },
    mounted(){

    },
    methods:{
        go(url){
            this.$router.push(url);
        },
        loginOut(){
            const loading = this.$loading({
                lock: true,
                text: '正在退出登录',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            Logout({
                "user_token": ""
            }).then((data)=>{
                loading.close();
                if(data.Code == 0){
                    this.$router.push('/Login');
                }
            }).catch(()=>{
                loading.close();
            })
        }
    }
}
</script>
<style scoped>
    #header{
        height: 100%;
        width: 100%;
        background: #101c35;
        padding: 0 20px;
        box-sizing: border-box;
        border-bottom: 2px solid #009aff;
    }
    .logo{
        height: 100%;
        display: flex;
        align-items: center;
        float: left;
        font-size: 22px;
        color: #ffffff;
    }
    .logo img{
        width: 38px;
        height: 38px;
        margin-right: 10px;
    }
    .loginInfo{
        float: right;
        height: 100%;
        max-width: 200px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .loginInfo .name{
        width: 100%;
        text-align: right;
        color: #ffffff;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        cursor: pointer;
    }
    
    .loginInfo:hover ul{
        display: block;
    }
    .loginInfo ul{
        display: none;
        width: 120px;
        height: auto;
        border-radius: 5px;
        background: #ffffff;
        box-shadow: 0px 4px 20px 4px rgb(136 136 136 / 50%);
        position: absolute;
        top: 100%;
        right: -10px;
        z-index: 2;
    }
    .loginInfo ul li{
        width: 100%;
        padding: 12px 0;
        font-size: 14px;
        border-bottom: 1px #eeeeee solid;
        text-align: center;
        cursor: pointer;
    }
    .loginInfo ul li.loginOut{
        color: #f5a100;
    }
</style>