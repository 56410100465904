<template>
    <el-container>
        <el-header>
            <Header></Header>
        </el-header>
        <el-container>
            <el-aside width="180px">
                <el-menu
                    :default-active="$route.path"
                    router
                    unique-opened
                    background-color="#ffffff"
                    text-color="#000000"
                    active-text-color="#009aff">
                    <el-submenu :index="submenu.path" v-for="(submenu,submenuIndex) in navs" :key="submenuIndex">
                        <template slot="title">
                            <span>{{submenu.text}}</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item :index="item.path" v-for="(item,index) in submenu.children" :key="index">
                                <template slot="title">
                                    <span>{{item.text}}</span>
                                </template>
                            </el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <el-main>
                <router-view/>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import Header from '@/views/common/Header';
export default {
    name:'Main',
    components:{
        Header
    },
    data(){
        return {
            navs:[
                {
                    text:"竞赛系统",
                    path:"/Match",
                    children:[
                        {text: '赛事管理', path: '/Match/CompetitionList'},
                        {text: '报名管理', path: '/Enroll/EnrollList'},
                        {text: '报名审核', path: '/Enroll/EnrollAuditList'}
                    ]
                }
            ]
        }
    }
}
</script>
<style>
.el-container{
    width: 100%;
    height: 100%;
}
.el-header{
    padding: 0;
}
.el-header + .el-container{
    height: calc(100% - 60px);
}
.el-aside{
    background: #101c35;
    border-right: 2px solid #009aff;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
}
.el-menu{
    border: none;
}
.el-submenu{
    border-bottom: 1px #eeeeee solid !important;
}
.el-submenu:last-of-type{
    border-bottom: none !important;
}
.el-menu-item.is-active{
    background: rgb(0, 154, 255) !important;
    color: #ffffff !important;
}
</style>